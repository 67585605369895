/* Navbar.css */
.navbar {
    background-color: #282c34;
    padding: 0.5rem 1rem; /* Adjust padding for the navbar */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-logo {
    height: 80px; /* Set a specific height */
    max-width: 100%; /* Prevent it from exceeding the container width */
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px; /* Space between the links */
  }
  
  .navbar-links li {
    display: inline;
  }
  
  .navbar-links a {
    color: #61dafb; /* Link color */
    text-decoration: none; /* Remove underline */
    font-size: 1.2rem; /* Font size for links */
    transition: color 0.3s; /* Smooth color transition */
  }
  
  .navbar-links a:hover {
    color: #21a1f1; /* Change color on hover */
  }
  