/* General body styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px;
}

/* Main container styling */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Table styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

/* Header row styling */
table thead {
    background-color: #007bff;
    color: #fff;
}

table thead th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
}

/* Data row styling */
table tbody tr {
    border-bottom: 1px solid #ddd;
}

table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tbody tr:hover {
    background-color: #e9f1ff;
}

/* Data cell styling */
table tbody td {
    padding: 10px;
    text-align: left;
    vertical-align: middle;
}

/* Buttons styling */
button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

button:hover {
    background-color: #0056b3;
}

/* Input field styling */
input[type="text"], input[type="email"] {
    padding: 10px;
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Section titles */
h2, h3 {
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
}
