/* General Styles */
body {
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

input[type="text"] {
    padding: 8px;
    width: 300px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th, table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

/* Pagination Buttons */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    input[type="text"] {
        width: 100%;
        margin-bottom: 10px;
    }

    select {
        width: 100%;
    }

    table {
        font-size: 14px;
    }

    .pagination button {
        font-size: 14px;
        padding: 8px 16px;
    }
}
